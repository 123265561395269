const questions = [
  // 1 Слайд
  {
    questionText:
      "Itu pun kamu! Dan saya telah mengasingkan barang. Ini bawa bersama dan yang ini ke dalam tong sampah. Pastinya yang satu ini saya tidak akan bawa bersama.",
    answerOptions: [
      {
        answerText:
          "Biarkan penyewa baharu akan menguruskan dengan barang lama.",
        answerResult:
          "Meminta bantuan pemandu adalah pilihan terbaik. Sementara dia memuatkan barang, saya boleh buang kotak berisi barang lama.",
        result: false,
      },
      {
        answerText:
          "Nyatakan ketika menempah perkhidmatan “Memuat dan memunggah” supaya pemandu boleh membantu.",
        answerResult:
          "Meminta bantuan pemandu adalah pilihan terbaik. Sementara dia memuatkan barang, saya boleh buang kotak berisi barang lama.",
        result: true,
      },
      {
        answerText: "Saya akan mengadakan jualan garaj di halaman rumah.",
        answerResult:
          "Meminta bantuan pemandu adalah pilihan terbaik. Sementara dia memuatkan barang, saya boleh buang kotak berisi barang lama.",
        result: false,
      },
    ],
    image: "images/01.webp",
  },

  // 2 слайд

  {
    questionText:
      "Tiba masanya untuk membawa barang-barang ke pangsapuri baharu! Anda rasa semuanya boleh dipindahkan sekaligus?",
    answerOptions: [
      {
        answerText: "Tidak mungkin. 3 trak masih tidak mencukupi.",
        answerResult:
          "Trak standard sudah mencukupi untuk semua barang ini. Pilihan saya ialah kadar Cargo.",
        result: false,
      },
      {
        answerText: "Tidak banyak barang dan muat dalam kereta biasa.",
        answerResult:
          "Trak standard sudah mencukupi untuk semua barang ini. Pilihan saya ialah kadar Cargo.",
        result: false,
      },
      {
        answerText: "Satu trak sudah mencukupi. ",
        answerResult:
          "Trak standard sudah mencukupi untuk semua barang ini. Pilihan saya ialah kadar Cargo.",
        result: true,
      },
    ],
    image: "images/02.webp",
  },

  // 3 слайд
  {
    questionText:
      "Kawan berjanji yang dia akan bertemu dengan pemandu di pangsapuri baharu. Bagaimana dia boleh tahu yang kenderaan telah tiba?",
    answerOptions: [
      {
        answerText:
          "Mudah saja! Boleh mengira masa perjalanan dan menelefon kawan.",
        answerResult:
          "Anda boleh bergantung kepada naluri atau mengira masa tetapi lebih selamat menghantar pautan laluan perjalanan. Lihat pergerakan kereta membawa barang dengan mudah pada peta dalam talian.",
        result: false,
      },
      {
        answerText: "Bergantung kepada naluri penguin anda.",
        answerResult:
          "Anda boleh bergantung kepada naluri atau mengira masa tetapi lebih selamat menghantar pautan laluan perjalanan. Lihat pergerakan kereta membawa barang dengan mudah pada peta dalam talian.",
        result: false,
      },
      {
        answerText:
          "Lihat pergerakan kereta membawa barang pada peta dalam talian.",
        answerResult:
          "Anda boleh bergantung kepada naluri atau mengira masa tetapi lebih selamat menghantar pautan laluan perjalanan. Lihat pergerakan kereta membawa barang dengan mudah pada peta dalam talian.",
        result: true,
      },
    ],
    image: "images/03.webp",
  },

  // 4 слайд
  {
    questionText:
      "Saya berikan kunci kepada penyewa baharu. Masa untuk pergi ke pangsapuri baharu!",
    answerOptions: [
      {
        answerText:
          "Tempahlah perjalanan supaya saya boleh sampai cepat dan menyusun barang.",
        answerResult:
          "Ya, berpindah bukanlah perkara mudah. Rasanya penguin kecil tidak boleh melakukannya sendiri, cepat bertemu dengannya.",
        result: true,
      },
      {
        answerText: "Bersiar-siar di sekitar bandar.",
        answerResult:
          "Ya, berpindah bukanlah perkara mudah. Rasanya penguin kecil tidak boleh melakukannya sendiri, cepat bertemu dengannya.",
        result: false,
      },
      {
        answerText: "Dan saya tidak mahu pergi.",
        answerResult:
          "Ya, berpindah bukanlah perkara mudah. Rasanya penguin kecil tidak boleh melakukannya sendiri, cepat bertemu dengannya.",
        result: true,
      },
    ],
    image: "images/04.webp",
  },

  // 5 слайд
  {
    questionText:
      "Berjaya! Pemandu telah membantu penguin kecil membawa semua barang ke arasnya. Bagaimana hendak mengucapkan terima kasih untuk kerja yang bagus?",
    answerOptions: [
      {
        answerText: "Tambah sebagai rakan di Facebook.",
        answerResult:
          "Berkomunikasi adalah bagus. Pemandu berasa lebih gembira apabila menerima tip untuk kerjanya. Jumlah ditambah kepada kos tempahan.",
        result: false,
      },
      {
        answerText: "Tinggalkan tip.",
        answerResult:
          "Berkomunikasi adalah bagus. Pemandu berasa lebih gembira apabila menerima tip untuk kerjanya. Jumlah ditambah kepada kos tempahan.",
        result: true,
      },
      {
        answerText: "Ucapkan “terima kasih”.",
        answerResult:
          "Berkomunikasi adalah bagus. Pemandu berasa lebih gembira apabila menerima tip untuk kerjanya. Jumlah ditambah kepada kos tempahan.",
        result: false,
      },
    ],
    image: "images/05.webp",
  },
];

export default questions;
